import {PolymerElement, html} from '@polymer/polymer/polymer-element.js';
/**
 * @polymer
 * @customElement
 * @extends {PolymerElement}
 */
window.JhaThumbnailElement = class extends PolymerElement {
  static get is() {
    return 'jha-thumbnail';
  }

  static get template() {
    return html`
    <style>
      :host {
        display: inline-block;
        width: 48px;
        height: 48px;
        border-radius: 2px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        text-align: center;
        vertical-align: middle;
        margin-right: 16px;
        position: relative;
      }
      :host ::slotted(img) {
        width: 100%;
        height: auto;
        border-radius: 2px;
        vertical-align: middle;
      }
      :host ::slotted(svg) {
        fill: var(--jha-text-white);
      }
      :host([x-small]) {
        width: 24px;
        height: 24px;
      }
      :host([x-small]) ::slotted(svg) {
        width: 18px;
        height: 18px;
      }
      :host([small]) {
        width: 36px;
        height: 36px;
      }
      :host([small]) ::slotted(svg) {
        width: 27px;
        height: 27px;
      }
      :host([large]) {
        width: 72px;
        height: 72px;
      }
      :host([large]) ::slotted(svg) {
        width: 54px;
        height: 54px;
      }
      :host([x-large]) {
        width: 96px;
        height: 96px;
      }
      :host([x-large]) ::slotted(svg) {
        width: 72px;
        height: 72px;
      }
      div {
        display: none;
        position: absolute;
        width: 20px;
        height: 20px;
        top: 30px;
        right: -6px;
      }
      :host([badge]) div {
        display: block;
      }
    </style>
    <slot></slot>
    <div>
      <slot name="badgeUrl"></slot>
    </div>
  `;
  }
};

customElements.define(window.JhaThumbnailElement.is, window.JhaThumbnailElement);
export default window.JhaThumbnailElement;
